<template>
<span>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 640 480" :width="width" :height="height" :style="{top: topPx, left: leftPx}">
  <defs>
    <filter id="u" x="-.13261" y="-.18282" width="1.2652" height="1.3656">
    <feGaussianBlur stdDeviation="0.238282"/>
    </filter>
    <filter id="t" x="-.10048" y="-.072604" width="1.201" height="1.1452">
    <feGaussianBlur stdDeviation="0.291171"/>
    </filter>
    <filter id="w" x="-.19534" y="-.096213" width="1.3907" height="1.1924">
    <feGaussianBlur stdDeviation="0.083936"/>
    </filter>
    <filter id="y" x="-.28225" y="-.19847" width="1.5645" height="1.3969">
    <feGaussianBlur stdDeviation="0.184837"/>
    </filter>
    <filter id="z" x="-.095137" y="-.34091" width="1.1903" height="1.6818">
    <feGaussianBlur stdDeviation="0.225993"/>
    </filter>
    <filter id="x" x="-.53738" y="-.27291" width="2.0748" height="1.5458">
    <feGaussianBlur stdDeviation="0.239244"/>
    </filter>
    <filter id="v" x="-.17213" y="-.090942" width="1.3443" height="1.1819">
    <feGaussianBlur stdDeviation="0.124334"/>
    </filter>
    <filter id="s" x="-.056877" y="-.2448" width="1.1138" height="1.4896">
    <feGaussianBlur stdDeviation="0.94351"/>
    </filter>
    <linearGradient id="ab" x1=".049903" x2="1.048" y1=".36905" y2=".36905">
    <stop stop-color="#d700d7" offset="0"/>
    <stop stop-color="#6e006e" offset="1"/>
    </linearGradient>
    <linearGradient id="ag" x1=".92117" x2=".46767" y1=".83158" y2=".83158">
    <stop stop-color="#a20000" offset="0"/>
    <stop stop-color="#d40000" offset="1"/>
    </linearGradient>
    <linearGradient id="b">
    <stop stop-color="#555" offset="0"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="a">
    <stop stop-color="#ffea00" offset="0"/>
    <stop stop-color="#ffffa4" offset="1"/>
    </linearGradient>
    <linearGradient id="af" x1=".82353" x2=".17647" y1=".82001" y2=".82001" xlink:href="#a"/>
    <linearGradient id="ac" x1=".78977" x2=".19602" y1=".67763" y2=".67763" xlink:href="#a"/>
    <linearGradient id="aa" x2="1.0001" y1=".44821" y2=".44821" xlink:href="#b"/>
    <linearGradient id="ae" x1=".97464" x2=".3253" y1="1.279" y2="1.279" xlink:href="#a"/>
    <linearGradient id="ad" x1=".60229" x2=".7671" y1="-75.752" y2="-75.752" gradientTransform="translate(-2.7933 1006)">
    <stop stop-color="#520" offset="0"/>
    <stop stop-color="#b6632c" offset="1"/>
    </linearGradient>
    <radialGradient id="ah" cx="16.668" cy="23.686" r="15.514" gradientTransform="matrix(2.6199 8.055 -10.893 7.1033 456.21 10255)" gradientUnits="userSpaceOnUse" xlink:href="#b"/>
  </defs>
  <g transform="rotate(13.23 311.34 239.19)">
  <path d="m403.04 322.74c4.3126 44.422-43.03 81.782-105.74 95.985-62.713 14.203-120.52-4.5088-121.36-54.716-0.75497-44.893 47.427-92.089 113.97-102.25 95.85-14.64 108.82 16.562 113.13 60.984z" :fill="fill1" fill-rule="evenodd" :stroke="stroke1"/>
    <path d="m223.37 353.23c2.5783-0.34244 6.6547-81.312 13.309-121.97 6.6547-40.656 13.309-60.984 13.309-101.64-6.6547-20.328-19.964-40.656-19.964-40.656s46.583 0 53.237 40.656c6.6547 40.656 33.273 111.8 39.928 132.13 6.6547 20.328 33.273 50.82 33.273 50.82 6.6547 60.984-113.13 101.64-133.09 40.656z" :fill="fill1" :stroke="stroke1"/>
    <path d="m349.18 303.37c-4.3187 11.846-8.8386 23.664-12.686 29.539-12.478 19.058-53.237 40.656-73.201 30.492-7.157-3.644-31.326-9.7-37.017-29.857-3.2444 7.9685-2.4586 19.104-2.9114 19.693 19.964 60.984 139.75 20.328 133.09-40.656 0 0-2.751-7.5411-7.2785-9.2111z" :fill="fill2" :stroke="stroke1"/>
    <path d="m336.08 325.6c-9.587 4.9126-17.984 13.459-26.203 22.551l10.398 25.728c9.7981-5.2793 19.4-10.913 26.203-21.598l-10.398-26.681zm-2.9114 8.2583l8.3183 17.469c-3.9481 5.6112-9.9522 11.223-18.612 16.834l-6.4467-19.534c2.194-2.5515 4.4164-5.2768 6.3427-6.1937l1.3517 3.8115 3.5353-3.3351-0.9358-3.6527c1.5394-1.9858 3.5251-3.8357 6.4467-5.3996z" :fill="fill1" :stroke="stroke1"/>
    <g fill="#fff">
    <path d="m226.28 288.44s-47.019 48.958-42.839 97.829c-13.886-34.864 17.049-83.304 42.839-97.829z" fill-opacity=".446" filter="url(#t)"/>
    <path d="m235.43 92.78c12.994-0.53848 19.729 6.0926 28.698 10.164 0 0-17.517 0.66193-12.062 21.599-7.517-15.982-16.637-31.763-16.637-31.763z" fill-opacity=".299" filter="url(#u)"/>
    <path d="m226.07 337.35c-1.1855 3.3977-2.3152 6.9952-1.8716 16.199 2.8627 5.2334 6.919 11.075 11.438 17.152-3.7746-7.6714-10.275-19.77-9.5661-33.351z" fill-opacity=".621" filter="url(#v)"/>
    <path d="m317.99 341.16l-6.8626 6.9877 5.4069 14.293c-1.5329-7.6075-3.4653-15.317 1.4557-21.281z" filter="url(#w)"/>
    </g>
    <g fill="#fff">
    <path d="m180.59 90.554c-6.3432 6.1373-4.139 13.907-3.4588 21.384 0 0 11.196-2.8226 3.4588-21.384z" fill-opacity=".473" filter="url(#x)"/>
    <path d="m326.06 217.52c2.0915 11.046 5.7734 17.232 9.685 22.717-0.023956-0.009323-13.822 0.79779-9.685-22.717z" filter="url(#y)"/>
    <path d="m331.36 50.078l-37.939-1.7972c11.405 4.5926 22.671 9.236 27.939 16.171-7.6684-7.0821-15.952-14.294 9.9993-14.374z" fill-opacity=".379" filter="url(#z)"/>
    </g>
  </g>
  </svg>
</span>
</template>

<script>
import ThemeMixin from '../theme_mixin'

export default {
  name: 'witch_hat',

  mixins: [ThemeMixin]
}
</script>
